@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,700;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant:wght@300;400;500;700&family=Montserrat:wght@300;400;700&display=swap);
/* Global */

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

:root {
    /* Colors */
    --black-primary: #1C1C1B;
    --black-secondary: #272727;
}

h1, h2, h3, h4, h5, h6, p, li, input, button, a, label, span {
    font-family: 'Open sans', sans-serif;
}

.panelMenu img, .panelContent--start img,
.panelContent__column__icon
{
    -webkit-filter: brightness(0) invert(1) !important;
            filter: brightness(0) invert(1) !important;
}

.button {
    cursor: pointer;
    outline: none;
    border: none;
    text-transform: uppercase;
}

a {
    text-decoration: none;
    cursor: pointer;
    color: inherit;
}

ul {
    list-style-type: none;
}

.d-mobile {
    display: none !important;
}

/* Login page */
.loginPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #1C1C1B;
    background: var(--black-primary);
}

.loginPage__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #272727;
    background: var(--black-secondary);
    border: 1px solid #c3c4c7;
    padding: 20px;
}

.label {
    display: flex;
    flex-direction: column;
    margin: 15px 0;
    font-weight: 400;
    font-size: 14px;
}

.input {
    margin: 4px 0;
    border: 1px solid #8c8f94;
    background: transparent;
}

.input--adminLogin {
    width: 300px;
    height: 50px;
    padding: 5px 10px;
}

.button--loginForm {
    background: #1C1C1B;
    background: var(--black-primary);
    color: #fff;
    font-weight: 700;
    width: 100%;
    height: 50px;
    transition: .4s all;
}

.button--loginForm:hover {
    letter-spacing: 2px;
}

.formError {
    font-size: 13px;
    color: #ee0000;
    font-weight: 400;
    margin-top: 20px;
    text-align: center;
}

/* Panel */
.panel {
    background: #272727;
    background: var(--black-secondary);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    overflow: hidden;
}

#menuItem--active {
    background: #1C1C1B;
    background: var(--black-primary);
}

.panelMenu__menu {
    display: block;
    background: #272727 !important;
    background: var(--black-secondary) !important;
}

@media(max-width: 776px) {
    .panelMenu__menu {
        display: none;
    }
}

.panelMenu__top {
    margin: 50px 20px 70px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.panelMenu__top__logoWrapper {
    width: 40%;
    height: auto;
    margin-right: 25px;
}

.panelMenu .panelMenu__top .panelMenu__top__logo {
    width: 100%;
    height: auto;
    -webkit-filter: none !important;
            filter: none !important;
}

.panelMenu__top__header {
    font-size: 14px;
    font-weight: 400;
}

.panelMenu__top__subheader {
    font-size: 16px;
}

.panelMenu {
    width: 20%;
    min-width: 200px;
    position: absolute;
    top: 0;
    left: 0;
    background: #272727;
    background: var(--black-secondary);
}

.panelMenu__list {
    margin-left: 0;
    padding-left: 0;
}

.panelMenu__list__item__img {
    width: 20px;
    height: auto;
}

.panelMenu__list__item__link {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    text-transform: uppercase;
    background: transparent;
    border: none;
    font-weight: 700;
    font-size: 17px;
    cursor: pointer;
    color: #fff !important;
    transition: .2s all ease-in-out;
}

.panelMenu__list__item__link:hover,
.panelMenu__list__subitem:hover
{
    background: #1C1C1B;
    background: var(--black-primary);
}

.panelMenu__list__item__img {
    margin: 0 20px;
}

.panelMenu__list__subitem {
    color: #fff !important;
}

.panelMenu__list__subitem__img {
    width: 20px;
    margin-right: 20px;
    margin-left: 40px;
}

.panelMenu__list__subitem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 30px 20px 30px 0;
    margin-left: 40px;
    font-weight: 700;
    text-transform: uppercase;
    transition: .2s all ease-in-out;
}

/* Panel - content */
.panelContent {
    background: #1C1C1B;
    background: var(--black-primary);
    width: 80%;
    min-height: 135vh;
    margin-left: 20%;
    padding: 60px 40px;
}

.panelContent__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.panelContent__header__back {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    color: #fff !important;
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
}

.panelContent__header__back__img {
    width: 35px;
    height: auto;
    margin-left: 30px;
    transition: .2s all ease-in-out;
}

.panelContent__header__back:hover .panelContent__header__back__img {
    -webkit-transform: translateX(12px);
            transform: translateX(12px);
}

/* Panel - orders */
.panelContent__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0;
    padding: 30px 0;
    border-bottom: 2px solid #E8E8E8;
}

.panelContent__column {
    padding: 0 15px;
    flex: 1 1;
}

.panelContent__column__label {
    color: #757575;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 15px;
    text-align: center;
    text-transform: uppercase;
}

.panelContent__column__value {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
}

.dateTime {
    display: block;
    text-align: center;
}

.panelContent__column__status {
    padding: 4px 8px;
}

.status--positive {
    background: #BCFFD9;
    border: 1px solid #12944B;
    color: #12944B;
    font-size: 12px;
    text-transform: uppercase;
}

.status--negative {
    background: #FFBCBC;
    border: 1px solid #941212;
    color: #941212;
    font-size: 12px;
    text-transform: uppercase;
}

.panelContent__column__btn {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.panelContent__column__value--buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.panelContent__column__icon {
    width: 15px;
    height: auto;
    margin: 0 10px;
}

.panelContent__filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 50px 0 40px;
}

.categoryList {
    margin-top: 50px;
}

.panelContent__filters__item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 30px;
}

.panelContent__filters__label {
    margin-right: 20px;
    color: #fff;
}

.panelContent__input {
    padding: 5px 10px;
    border: none;
    outline: none;
    background: transparent;
}

.panelContent__input::-webkit-input-placeholder {
    color: #757575;
}

.panelContent__input:-ms-input-placeholder {
    color: #757575;
}

.panelContent__input::placeholder {
    color: #757575;
}

.panelContent__sortBtn {
    background: transparent;
    color: #757575;
    font-size: 14px;
    border: none;
    margin: 0 15px;
    cursor: pointer;
}

.panelContent__sortBtn--active {
    text-decoration: underline;
}

.panelContent__filters__label__label {
    color: #757575;
    font-size: 14px;
    margin: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.addProduct__categorySelect .panelContent__filters__label__label {
    justify-content: flex-start;
}

.panelContent__filters__btn {
    width: 20px;
    height: 20px;
    border: 1px solid #000;
    margin-right: 7px;
    background: #fff;
    cursor: pointer;
    padding: 4px;
}

.d-none {
    display: none;
}

.panelContent__filters__btn--active {
    background: #000;
    display: block;
    width: 100%;
    height: 100%;
}

.panelContent__input__label {
    border: 1px solid #fff;
}

.panelContent__input__span {
    padding: 0 12px;
}

.panelContent__input__icon {
    -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
}

/* Add product page */
.editor {
    background: #fff;
    border: 1px solid #000;
    padding: 0 10px;
    min-height: 300px;
}

.allergensImg {
    width: 40px;
    height: auto;
}

.editorWrapper {
    max-width: 600px;
}

.addProduct {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: #1C1C1B;
    background: var(--black-primary);
    padding: 50px;
}

.addProduct__header__h {
    text-align: left;
    font-size: 26px;
    margin-bottom: 20px;
    width: 100%;
}

.addProduct__header {
    width: 100%;
    margin: 40px 0;
    border-bottom: 1px solid #E8E8E8;
}

.addProduct__label {
    display: block;
    margin: 25px 0;
}

.fileInputLabel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 700;
}

.addProduct__form {
    width: 600px;
    padding-bottom: 100px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.addProduct__form__section {
    width: 46%;
}

.addProduct__form--addProduct {
    display: flex;
    width: 80%;
    max-width: 1600px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.addProduct__btnWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.addProduct__input {
    padding: 5px 10px;
    width: 100%;
    height: 40px;
    opacity: 1;
    background: transparent;
    border: 1px solid #fff;
    outline: none;
    color: #fff;
}

.fileUploader {
    margin: 30px 0;
}

.addProduct__input::-webkit-input-placeholder {
    color: #757575;
}

.addProduct__input:-ms-input-placeholder {
    color: #757575;
}

.addProduct__input::placeholder {
    color: #757575;
}

.addProduct__categorySelect {
    width: 100%;
}

.addProduct__btn {
    width: 100%;
    height: 60px;
    color: #fff;
    border: 2px solid #000000;
    text-transform: uppercase;
    font-weight: 700;
    background: transparent;
    cursor: pointer;
    transition: .4s all ease-in-out;
}

.addProduct__btn:hover {
    color: #fff;
    background: #000;
}

.addProduct__label--file {
    width: 100%;
    height: 200px;
    border: 2px dashed #B4B4B4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #8c8f94;
    text-transform: uppercase;
    cursor: pointer;
}

.addProduct__fileImg {
    width: 40px;
    height: auto;
    margin-bottom: 25px;
}

.addProduct__allergies {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    margin: 10px auto 50px;
}

.addProduct__allergies__item {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
    cursor: pointer;
    transition: .2s all ease-in-out;
    opacity: .3;
}

input[type=file] {
    display: block;
    margin: 18px auto;
    width: 200px;
}

.panelContent__productImg {
    width: 120px;
    height: auto;
}

.invisibleInput {
    visibility: hidden;
    height: 0;
}

.addProduct__form--addProduct > .addProduct__form__section > label:first-of-type {
    margin-top: 0;
}

.addProduct__form__subsection {
    display: flex;
    flex-direction: column;
}

.panelContent__filters__btn {
    margin-left: 20px;
}

.panelContent__filters__btn--options {
    margin-right: 20px;
    margin-left: 0;
}

.panelContent__filters__btnWrapper {
    margin: 10px 0;
    cursor: pointer;
}

.marginTop30 {
    margin-top: 30px;
}

.addProduct__form__subsection--marginLeft {
    margin-left: 120px;
}

.addProduct__input--invisible {
    display: block;
    opacity: 0;
    height: 0;
    margin: 0;
    padding: 0;
    transition: .4s all ease-in-out;
}

.jodit-container {
    margin-top: 15px;
    margin-bottom: 30px;
}

.jodit--label span {
    font-size: 14px;
}

.input--hidden {
    visibility: hidden !important;
}

.addMsg {
    margin-top: 100px;
}

/* Panel - categories */
.panelContent__frame {
    background: transparent;
    padding: 25px;
    margin-top: 50px;
}

.panelContent__frame__header {
    font-size: 24px;
    padding-bottom: 20px;
    border-bottom: 1px solid #E8E8E8;
}

.addedMsgWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 40px;
}

.panelContent__frame__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 500px;
    margin: 40px auto;
}

.addProduct__categorySelect {
    width: 100%;
    display: block;
}

.addProduct__label--frame {
    width: 100%;
    font-size: 13px;
    text-align: center;
    text-transform: uppercase;
    color: #8c8f94;
}

.panelContent__input__label--payment {
    margin: 10px 0;
    width: 100%;
}

.panelContent__input__label--payment .panelContent__input {
    width: 100%;
    height: 40px;
    color: #fff;
}

/* Panel - settings */
.panelContent__twoForms {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.addProduct__label--frame--margin {
    margin: 10px 0;
}

.marginTop10 {
    margin-top: 10px;
}

.formError--panel {
    color: red;
    font-size: 12px;
    text-transform: none;
    padding-top: 4px;
    display: block;
}

.formMsg {
    text-align: center;
    font-size: 20px;
    margin: 30px 0;
}

/* Modal on delete */
.panelModal .ReactModal__Overlay {
    background: rgba(0, 0, 0, .6) !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.panelModal .ReactModal__Content {
    width: 400px !important;
    height: 400px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #1C1C1B !important;
    background: var(--black-primary) !important;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.modalClose {
    background: transparent;
    position: absolute;
    right: 20px;
    top: 20px;
    width: 20px;
    height: auto;
    border: none;
    cursor: pointer;
    -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
}

.modalClose__img {
    width: 100%;
    height: auto;
}

.modalQuestion {
    font-size: 18px;
    text-align: center;
    padding: 20px;
}

.modalQuestion__btn {
    margin: 0 20px;
    background: #272727;
    background: var(--black-secondary);
    color: #fff;
    padding: 10px 20px;
    cursor: pointer;
    transition: .4s all ease-in-out;
}

.modalQuestion__btn:hover {
    background: #000;
    color: #fff;
}

/* Panel - payment */
.labelSpan {
    color: #8c8f94;
    font-size: 13px;
    display: block;
    margin-top: 15px;
    text-align: left;
    width: 100%;
}

.paymentMethods__msg {
    margin-top: 25px;
}

/* Order details */
.panelContent__header--smaller {
    font-size: 17px;
    font-weight: 400;
}

.panelContent__header--smaller b {
    font-size: 21px;
}

.panelContent__cart__content {
    margin: 40px 0 40px 20px;
    max-width: 1000px;
    position: relative;
}

.panelContent__cart__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0;
}

.panelContent__cart__column:last-of-type {
    flex: 4 1;
}

.panelContent__cart__column {
    flex: 3 1;
}

.panelContent__cart__item span {
    font-size: 15px;
    font-weight: 700;
}

.panelContent__cart__sum {
    position: absolute;
    bottom: -100px;
    right: 0;
    -webkit-transform: translateX(0);
            transform: translateX(0);
}

.panelContent__cart__column {
    text-align: right;
}

.panelContent__orderDetails {
    display: flex;
    justify-content: flex-start;
    align-self: flex-start;
    margin-top: 200px;
}

.panelContent__clientData {
    width: 45%;
    margin-right: 50px;
}

.panelContent__shipping {
    width: 45%;
}

.panelContent__clientData__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin: 20px 0;
}

.panelContent__data {
    font-size: 15px;
    font-weight: 400;
    border: 1px solid #000;
    padding: 20px;
    color: #757575;
    margin: 8px 0;
}

.w-100 {
    width: 100%;
}

.w-50 {
    width: 48%;
}

.w-70 {
    width: 58%;
}

.w-30 {
    width: 38%;
}

.w-20 {
    width: 20%;
}

.panelContent__orderStatus__header {
    margin-top: 30px;
    font-size: 20px;
}

.panelContent__orderStatus__img {
    margin-left: 20px;
    width: 30px;
    height: auto;
}

.panelContent__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.panelContent__cart__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.panelContent__btn {
    margin: 0 20px;
    text-transform: uppercase;
    font-size: 11px;
    cursor: pointer;
    transition: .4s all ease-in-out;
}

.btn--red {
    border: 1px solid #941212;
    background: #FFBCBC;
    color: #941212;
    padding: 5px 10px;
}

.btn--red:hover {
    background: #941212;
    color: #fff;
}

.btn--neutral {
    background: #BCFFD9;
    color: #12944B;
    border: 1px solid #12944B;
    transition: .4s all ease-in-out;
}

.btn--neutral:hover {
    background: #12944B;
    color: #fff;
}

.button--link {
    background: transparent;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
}

.product__fileInput {
    margin-bottom: 50px !important;
}

.allergiesChecked {
    opacity: 1;
}

/* Start panel page */
.panelContent__startContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 100px;
    height: 60vh;
}

.panelContent__start__item {
    border: 2px solid #F1F1F1;
    background: #272727;
    background: var(--black-secondary);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 29%;
    padding: 70px 30px;
    margin-bottom: 30px;
    opacity: .7;
    transition: .3s all;
}

.panelContent__start__item:hover {
    opacity: 1;
}

.panelContent__start__item__img {
    width: 90px;
    height: auto;
}

.panelContent__start__item__header {
    margin-top: 25px;
}

.settingsForm {
    width: 48%;
    max-width: 500px;
}

.panelContent__frame__main {
    margin-top: 30px;
}

.panelContent__ribbons__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 20px;
    border: 1px solid black;
}

.panelContent__ribbons__column {
    font-weight: 400;
    font-size: 15px;
    margin-right: 30px;
    padding: 10px 0;
}

.panelContent__ribbons__column--caption {
    border-left: 1px solid #000;
    padding-left: 10px;
}

.panelContent__filters__label__label--category {
    margin-bottom: 40px;
}

/* Blog */
.addProduct__form--addPost {
    flex-direction: column;
}

.addProduct__form--addPost, .panelContent__addPost,
.panelContent__addPost .jodit--label
{
    width: 100%;
}

.panelContent__addPost .jodit-workplace {
    min-height: 500px !important;
}

.addProduct__label--addPost {
    width: 100%;
    margin-bottom: 30px;
}

.en-division {
    display: block;
    margin: 40px 0;
    width: 100%;
    height: 2px;
    background: rgba(0, 0, 0, .25);
}

/* Others */
.panelContent--others {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.panelContent__othersSection {
    width: 45%;
}

.panelContent__ribbons__column__img {
    width: 20px;
}

.shippingForm {
    width: 100%;
}

.btn--maxWidth {
    max-width: 400px;
}

.d-flex .jodit--label {
    width: 45%;
}

.marginBottom40 {
    margin-bottom: 40px;
}

.errorsContainer {
    color: red;
    font-size: 13px;
    margin-bottom: 10px;
}

.cart--flex--admin {
    flex-wrap: wrap;
}

.cart--flex--admin .addProduct__btn {
    max-width: 400px;
    margin: 20px auto;
}

.marginTop20 {
    margin-top: 20px;
    display: block;
}

.w-100 {
    width: 100%;
}

.smaller {
    font-size: 15px;
    font-style: italic;
    font-weight: 400;
}

.jodit, .jodit *, .jodit-container, .jodit-container * {
    color: #000 !important;
}

select {
    background: #272727;
    background: var(--black-secondary);
    color: #fff;
}

/* Modifications */
.d-flex > .addProduct__input {
    width: 47%;
}

.panelContent__cart__column,
.panelContent__data

{
    color: #fff;
    border-color: #fff;
}

.panelContent__orderStatus__img {
    -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
}

.inPost__address__header {
    font-size: 17px;
    margin-bottom: 15px;
}

.inPost__address {
    color: #fff;
    margin-top: 40px;
}

.panelContent__filters__btnWrapper:last-child .panelContent__filters__btn {
    margin-left: 15px;
}

.addShipping__input {
    max-width: 500px;
}

.btn--editSectionHeader {
    max-width: 400px;
}

.pl-5 {
    padding-left: 25px;
}

/* Update - 20.08.21 */
.mainProductImage, .galleryProductImage {
   height: 150px;
    width: auto;
    margin: 15px;
    cursor: pointer;
}

.galleryProductImage--main {
    border: 3px solid #000;
}

.galleryWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

/* Stocks */
.addProduct--stock {
    align-items: flex-start;
}

.stockForm {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.chooseProducts {
    margin-left: 80px;
}

.panelContent--productList__item {
    color: #fff !important;
    margin-left: 0;
    justify-content: flex-start;
}

.panelContent--productList__item .panelContent__filters__btn {
    margin-left: 0;
}

.stockTitleLabel {
    width: 100%;
    margin: 30px auto;
    font-size: 14px;
}

.stockTitleInput {
    width: 100%;
    height: 50px;
    margin-top: 10px;
    border: 1px solid #fff;
    background: transparent;
    padding: 10px;
    outline: none;
}

.infoHeader--stock {
    width: 100%;
}

/* Update - 06.09.21 */
.panelContent__input--letterNumber {
    border: 1px solid #fff;
    margin: 0 15px;
}

.panelContent__select {
    margin: 0 15px;
}

.panelContent__editOrderBtn {
    color: #fff;
    background: transparent;
    border: 2px solid #fff;
    margin-top: 20px;
    width: 100%;
    height: 50px;
    max-width: 300px;
}

/* Update - 20.09.21 */
.panelContent__column__status {
    display: flex;
    justify-content: center;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: -moz-max-content;
    margin: auto;
}

.panel {
    background: #272727;
}

.panel * {
    font-family: 'Open sans', sans-serif;
}

/* Update - 22.09.21 */
.orderDetails * {
    font-family: 'Open sans', sans-serif;
}

.mt-4 {
    margin-top: 20px;
}

.orderDetails .button--link {
    color: #12944B !important;
}

.orderDetails .button--link:hover {
    color: #fff !important;
}

.addShipping__input {
    margin: 0 auto 30px;
}

@media(max-width: 1200px) {
    /* Admin */
    .panelMenu__top__logoWrapper {
        display: none;
    }

    .panelContent__item {
        flex-wrap: wrap;
    }

    .orderItem .panelContent__column {
        flex-basis: 30%;
        margin-bottom: 30px;
    }

    .panelContent__column__label {
        margin-bottom: 7px;
    }

    .addProduct__form--addProduct {
        flex-wrap: wrap;
    }

    .addProduct__form--addProduct > * {
        width: 100% !important;
    }

    .addProduct__form__subsection--marginLeft {
        margin-left: 0;
    }
}

@media(max-width: 996px) {
    /* Admin */
    .panel {
        display: block;
    }

    .panelMenu {
        z-index: 10;
        top: 0;
        left: 0;
        width: 100%;
        height: 80px;
        background: #000;
        padding: 10px 5%;
    }

    .panelMenu__menuMobile {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .panelContent {
        width: 100%;
        padding: 20px 5%;
        margin-left: 0;
        margin-top: 100px;
    }

    .panelMenu__menuMobile__hamburgerWrapper {
        background: transparent;
    }

    .panelMenu__menuMobile__hamburgerImg {
        width: 30px;
        height: auto;
        -webkit-filter: brightness(0) invert(1);
                filter: brightness(0) invert(1);
    }

    .panelMenu__top__header,
    .panelMenu__top__subheader {
        color: #fff;
    }

    .panelContent__header__back,
    .panelContent__header__back:hover .panelContent__header__back__img
    {
        -webkit-transform: none;
                transform: none;
    }

    .panelContent__cart__column {
        font-size: 14px;
    }

    .panelContent__cart__item {
        padding-bottom: 15px;
    }

    .panelContent__cart__item:not(:last-of-type) {
        border-bottom: 1px solid rgba(0, 0, 0, .25);
    }

    .panelMenu__menu {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: #fff;
        z-index: 11;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .panelMenu__list {
        width: 100%;
    }

    .panelMenu__menuMobile__closeBtn {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 30px;
        background: transparent;
    }

    .panelMenu__menuMobile__closeBtn__img {
        width: 100%;
        height: auto;
    }

    .addProduct__header, .addProduct__header__h {
        margin: 15px 0;
        font-size: 18px;
    }

    .panelContent__ribbons__column {
        font-size: 13px;
    }
}

@media(max-width: 768px) {
    /* Admin */
    .orderItem .panelContent__column {
        flex-basis: 49%;
    }

    .panelContent__column__value,
    .panelContent__column__label {
        font-size: 12px !important;
    }

    .panelContent__filters__label,
    .panelContent__filters__label__label,
    .panelContent__sortBtn
    {
        font-size: 13px;
    }

    .panelContent__filters__label__label {
        margin: 0;
    }

    .panelContent__filters__label {
        margin-right: 5px;
    }

    .panelContent__input__label {
        width: 180px;
    }

    .panelContent__input {
        width: calc(100% - 20px);
    }

    .panelContent__filters__btn {
        margin-left: 10px;
    }

    .productItem .panelContent__column {
        flex-basis: 48%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .panelContent__frame__form {
        width: 100%;
        margin: 0;
    }

    .panelContent__frame__header {
        font-size: 17px;
    }

    .categoriesForm .addProduct__label {
        margin: 10px 0;
    }

    .paymentContent .labelSpan {
        text-align: center;
    }

    .panelContent__input__label--payment {
        width: 100%;
    }

    .addProduct__btn {
        padding: 10px;
    }

    .panelContent__twoForms {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .settingsForm {
        width: 100%;
        margin: 40px 0;
    }

    .panelContent__cart__item {
        flex-wrap: wrap;
    }

    .panelContent__cart__column {
        flex-basis: 30%;
        text-align: left;
        white-space: nowrap;
    }

    .panelContent__data {
        width: 100% !important;
        padding: 12px;
        font-size: 13px;
    }

    .panelContent__cart__header {
        flex-direction: column;
        align-items: flex-start;
    }

    .panelContent__cart__header > *:last-child {
        margin-bottom: 20px;
    }

    .panelContent__cart__header .btn--neutral {
        margin-left: 0;
    }

    .panelContent__cart__column {
        border: 1px solid rgba(0, 0, 0, .25);
        padding: 10px !important;
    }

    .panelContent__cart__column span {
        font-weight: 400;
        font-size: 13px;
    }

    .panelContent__header--smaller {
        order: 2;
    }

    .panelContent__buttons {
        order: 1;
    }

    .addProduct__form--addProduct {
        width: 100%;
    }

    .panelContent__ribbons__item {
        flex-direction: column;
        align-items: flex-start;
    }

    .panelContent__ribbons__column--caption {
        border-left: none;
        padding-left: 0;
        border-top: 1px solid #000;
    }

    .d-mobile {
        display: block !important;
    }

    .d-desktop {
        display: none !important;
    }
}

@media(max-width: 576px) {
    /* Admin */
    .panelContent__start__item {
        width: 48%;
    }

    .panelContent__header__h {
        font-size: 17px;
    }

    .panelContent__header__back {
        font-size: 13px;
    }

    .panelContent__header__back__img {
        width: 18px;
        margin-left: 16px;
    }

    .panelModal .ReactModal__Content {
        width: 100% !important;
        height: 100vh !important;
    }
}

/* Global */

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    /*scroll-behavior: smooth;*/
}

body {
    position: relative;
    background: #000;
}

h1, h2, h3, h4, h5, h6, button {
    font-family: 'Cormorant', serif;
    color: #fff;
}

li, a, p, section,
.topBar__language__inner,
.heroCarousel__subheader
{
    font-family: 'Montserrat', sans-serif;
    color: #fff !important;
}

button, a {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

a {
    text-decoration: none;
    color: inherit;
}

html, body {
    overflow-x: hidden;
}

.container {
    width: 100%;
    overflow-x: hidden;
}

.d-mobile {
    display: none;
}

:root {
    --gold: #B56437;
    --dark: #1E1E1E;
    --siteWidth: clamp(200px, 85vw, 2300px);
}

/* Hero loader */
.heroLoader {
    width: 100%;
    height: 100vh;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    transition: 2s all;
}

.heroLoader__img {
    -webkit-transform: scale(.4);
            transform: scale(.4);
    max-width: 100%;
    transition: 20s all;
}

/* Hero section */
.heroCarousel {
    display: none;
    opacity: 0;
    max-height: 100vh;
    transition: 2s all ease-in-out;
    min-height: 600px;
    position: relative;
}

.heroCarousel__content {
    width: clamp(200px, 85vw, 2300px);
    width: var(--siteWidth);
    margin: auto;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 50%;
    height: 100%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.heroCarousel__buttons {
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 2;
    opacity: .5;
}

.heroCarousel__buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.heroCarousel__button {
    width: 30px;
    height: 30px;
    background: transparent;
    border: 2px solid #B56437;
    border: 2px solid var(--gold);
    border-radius: 50%;
    margin-bottom: 15px;
    position: relative;
}

.heroCarousel__arrows {
    position: absolute;
    right: 0;
    bottom: 5%;
}

.heroCarousel__buttonArrow {
    background: transparent;
    border: none;
    outline: none;
    margin: 0 20px;
}

.heroCarousel__buttonArrow__arrow {
    width: 10px;
    height: auto;
}

.buttonArrow--next {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

.heroCarousel__image {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.heroCarousel__image__img {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    -webkit-filter: brightness(.5);
            filter: brightness(.5);
    transition: 1.5s all ease;
}

#hero2, #hero3 {
    opacity: 0;
}

.heroCarousel__button--selected {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 15px;
    height: 15px;
    background: #B56437;
    background: var(--gold);
    border-radius: 50%;
}

/* Top bar */
.topBar {
    margin-top: 25px;
    width: 100%;
}

.container > .topBar, .container > .topMenu {
    width: clamp(200px, 85vw, 2300px);
    width: var(--siteWidth);
    margin: auto;
}

.container > .topBar {
    margin-top: 20px;
}

.topBar__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.topBar__facebookLink, .topBar__shop {
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.topBar__facebookLink {
    opacity: .85;
    transition: .3s all ease-in-out;
}

.topBar__facebookLink:hover {
    opacity: 1;
}

.topBar__facebookLink__img {
    margin-right: 15px;
}

.topBar__logoLink__img {
    display: block;
    width: 570px;
    margin: 15px auto;
}

.topBar__bottom {
    position: relative;
}

.topBar__shop {
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    text-transform: uppercase;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(255, 255, 255, .5);
    opacity: .75;
    transition: .2s all;
}

.topBar__shop:hover {
    opacity: 1;
}

.topBar__shop__img {
    margin-left: 15px;
}

.topBar__language {
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
}

.topBar__language__inner {
    margin-left: 15px;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(255, 255, 255, .5);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    cursor: pointer;
}

.topBar__language__inner--second {
    position: absolute;
    bottom: -45px;
    right: 0;
    z-index: 2;
    display: none;
    opacity: 0;
    border-bottom: none;
    transition: .5s all;
}

.topBar__language__inner--second--portfolio {
    cursor: pointer;
    background: #000;
    border: none;
    outline: none;
    z-index: 2;
    text-transform: uppercase;
}

@media(max-width: 996px) {
    .topBar__language__inner--second--portfolio {
        -webkit-transform: translateX(-10px);
                transform: translateX(-10px);
    }

    .topBar__language__inner--second--portfolio span {
        padding: 0 10px;
    }
}

.flag {
    width: 25px;
    height: auto;
}

.languageBtn {
    background: transparent;
    border: none;
    outline: none;
    margin-left: 10px;
}

.languageBtn__img {
    transition: .4s all;
}

.flag {
    margin-right: 10px;
}

/* Top menu */
.topMenu {
    border-bottom: 1px solid rgba(255, 255, 255, .5);
}

.topMenu__list {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
}

.topMenu__item {
    position: relative;
}

.topMenu__link {
    text-transform: uppercase;
    color: #fff;
    transition: .3s all;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.topMenu__link:hover {
    color: #B56437 !important;
    color: var(--gold) !important;
}

.dropdownMenuIcon {
    margin-left: 15px;
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg);
    transition: .4s all;
}

.topMenu__submenu {
    background: rgba(0, 0, 0, .56);
    list-style-type: none;
    position: absolute;
    top: calc(100% + 17px);
    left: 0;
    min-width: 250px;
    text-align: center;
    display: none;
    opacity: 0;
    padding: 0 40%;
    transition: .4s opacity ease;
}

.topMenu__submenu::before {
    content: "";
    background: transparent;
    z-index: 0;
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    height: 30px;
}

.topMenu__submenu:hover {
    display: block;
    opacity: 1;
}

.topMenu__submenu__item {
    padding: 12px 0;
}

.topMenu__submenu__item:not(:last-of-type) {
    border-bottom: 1px solid #B56437;
    border-bottom: 1px solid var(--gold);
}

.topMenu__submenu__link {
    font-size: 13px;
    transition: .3s all;
}

.topMenu__link:hover .topMenu__submenu {
    display: block;
    opacity: 1;
}

.topMenu__link:hover .dropdownMenuIcon {
    -webkit-transform: none;
            transform: none;
}

.topMenu__submenu__link:hover {
    color: #B56437 !important;
    color: var(--gold) !important;
}

/* Landing page */
.heroCarousel__header {
    font-size: 64px;
    margin-bottom: 25px;
    margin-top: 12%;
    font-weight: 400;
}

.heroCarousel__subheader {
    font-size: 22px;
    font-weight: 300;
}

.button {
    background: transparent;
    border: 1px solid #B56437;
    border: 1px solid var(--gold);
    color: #fff;
    text-transform: uppercase;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .7s all ease-in-out;
}

.button--landing {
    height: 100px;
    width: 450px;
    margin-top: 50px;
    font-size: 32px;
    font-weight: 300;
    font-family: 'Cormorant', serif;
    position: relative;
    z-index: 9999;
}

/* Section header */
.sectionHeader {
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-weight: 300;
    margin: 30px 0;
    padding-bottom: 14px;
    border-bottom: 1px solid #fff;
    position: relative;
}

.sectionHeader--right {
    text-align: right;
}

.sectionHeader::after {
    content: "";
    position: absolute;
    bottom: -1px;
    width: 150px;
    background: #B56437;
    background: var(--gold);
    height: 2px;
}

.sectionHeader--left::after {
    left: 0;
}

.sectionHeader--right::after {
    right: 0;
}

.section {
    position: relative;
    z-index: 3;
    width: clamp(200px, 85vw, 2300px);
    width: var(--siteWidth);
    margin: auto;
}

.section--aboutUs .sectionHeader::after {
    width: 75px;
}

.section--creationProcess .sectionHeader::after {
    width: 205px;
}

.section--portfolio .sectionHeader::after {
    width: 105px;
}

.section--contact .sectionHeader::after {
    width: 100px;
}

/* About us */
.aboutUs {
    position: relative;
    margin-top: 100vh;
}

.absolute {
    position: absolute;
}

.aboutUs__img--1 {
    left: calc((100vw - clamp(200px, 85vw, 2300px)) / 2 * (-1));
    left: calc((100vw - var(--siteWidth)) / 2 * (-1));
    top: 0;
}

.aboutUs__img--2 {
    right: 0;
    top: 10vh;
}

.aboutUs__content {
    margin-left: clamp(50px, 20%, 340px);
    padding-top: 5vh;
    max-width: 50%;
    padding-bottom: 5vh;
}

.aboutUs__header {
    font-size: 36px;
    margin-bottom: 30px;
    font-weight: 400;
}

.aboutUs__text {
    font-size: 17px;
    margin-bottom: 25px;
}

.button--aboutUs {
    height: 70px;
    width: 400px;
    margin-top: 50px;
    font-size: 24px;
    font-weight: 300;
    font-family: 'Cormorant', serif;
}

/* Technologies */
.section--technologies {
    margin-top: 200px;
    margin-bottom: 300px;
}

.technologies {
    position: relative;
    margin-top: 70px;
}

.img--technologies1 {
    top: 0;
    left: calc((100vw - clamp(200px, 85vw, 2300px)) / 2 * (-1));
    left: calc((100vw - var(--siteWidth)) / 2 * (-1));
}

.aboutUs__header--technologies {
    max-width: 400px;
    margin-left: clamp(200px, 35%, 700px);
}

.technology--1 {
    margin-left: clamp(200px, 35%, 700px);
    margin-top: 100px;
}

.technology {
    position: relative;
    max-width: 600px;
}

.technology__number {
    font-weight: 700;
    font-family: 'Cormorant', serif;
    font-size: 190px;
    line-height: 50px;
    color: #1E1E1E;
    position: absolute;
    top: 0;
    left: 0;
}

.technology__name {
    font-size: 48px;
    padding-top: 15px;
    margin-left: 50px;
    position: relative;
    z-index: 2;
}

.technology__desc {
    margin-top: 30px;
    margin-left: 50px;
    position: relative;
    z-index: 2;
}

.button--technology {
    height: 70px;
    width: 350px;
    margin-top: 40px;
    margin-left: 50px;
    font-size: 24px;
    font-weight: 300;
    font-family: 'Cormorant', serif;
}

.img--technologies2 {
    right: 0;
    top: 700px;
}

.img--technologies3 {
    left: 10%;
    top: 1200px;
}

.technology--2 {
    margin-left: 20%;
    margin-top: 300px;
}

.technology--3 {
    margin-left: 60%;
    margin-top: 300px;
}

/* Creation process */
.creationProcess {
    margin: 50px 0;
}

.creationProcess__desc {
    text-align: center;
    max-width: 900px;
    margin: 15px auto;
}

.button--process {
    height: 80px;
    width: 420px;
    text-align: center;
    margin: 40px auto 20px;
    font-size: 20px;
    font-weight: 300;
    font-family: 'Cormorant', serif;
}

/* Portfolio */
.portfolioSection {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

#sectionPicker {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.portfolioItem {
    width: 30%;
    height: auto;
}

.portfolioItem__img {
    width: 100%;
    height: auto;
}

.button--portfolio {
    width: 450px;
}

/* Contact */
.contact {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 50px 0 100px;
}

.contact__map {
    width: 55%;
    height: 400px;
}

.contact__content {
    width: 40%;
}

.contact__header {
    font-size: 48px;
    font-weight: 400;
    margin-bottom: 30px;
}

.contact__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
}

.contact__item__icon {
    width: 30px;
    height: auto;
    margin-right: 20px;
}

.contact__item__text {
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

#googleMap > div {
    position: relative !important;
}

/* Footer */
.footer {
    border-top: 1px solid #fff;
    padding: 40px 0 120px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.footer__section {
    width: 30%;
}

.footerHeader {
    position: relative;
    font-size: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid #fff;
    margin-bottom: 25px;
}

.footerHeader::after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 30%;
    height: 2px;
    background: #B56437;
    background: var(--gold);
}

.footerHeader--navigation::after {
    width: 100px;
}

.footerHeader--links::after {
    width: 60px;
}

.footerHeader--shop::after {
    width: 125px;
}

.footer__list {
    list-style-type: none;
}

.footer__list__item {
    margin: 10px 0;
}

.footer__list__item__link {
    font-size: 15px;
    transition: .3s all;
}

.footer__list__item__link:hover {
    color: #B56437 !important;
    color: var(--gold) !important;
}

.footer__list--flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.footer__list--flex .footer__list__item {
    width: 47%;
}

.footer__bottom {
    margin-top: 30px;
    border-top: 1px solid rgba(255, 255, 255, .25);
    padding: 20px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer__bottom__header {
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

.footer__bottom__header__link {
    color: #fff;
    opacity: .7;
    transition: .3s all;
}

.footer__bottom__header__link:hover {
    opacity: 1;
}

/* Button hover effect */
.button {
    position: relative;
    z-index: 5;
    background:transparent;
    background-position: 1% 100%;
    background-size: 300% 300%;
    background-image: linear-gradient(45deg, transparent 0%, transparent 75%, rgba(235,235,235,0.5) 75%, transparent 100%);
}

.button:hover {
    background-position:150% top;
}

@media(-webkit-min-device-pixel-ratio: 1.05) {
    .heroCarousel__header {
        font-size: 38px;
    }

    .heroCarousel__subheader {
        font-size: 21px;
    }

    .button--landing {
        -webkit-transform-origin: tpageContent__witraze__imgop left;
                transform-origin: tpageContent__witraze__imgop left;
    }

    @media(max-width: 996px) {
        .button--landing {
            -webkit-transform-origin: top center;
                    transform-origin: top center;
        }
    }

    @media(min-width: 1200px) {
        .img--technologies3,
        .button--landing,
        .topBar__logoLink__img {
            -webkit-transform: scale(.8) !important;
                    transform: scale(.8) !important;
        }
    }

    .topMenu__link {
        font-size: 13px;
    }
}

/* Background - layers */
.background {
    position: absolute;
    z-index: 1;
}

.background--1 {
    top: 50vh;
    left: -240px;
}

.background--2 {
    right: -300px;
    top: 120vh;
}

.background--3 {
    top: 220vh;
    left: -300px;
}

.background--4 {
    top: 2500px;
    right: -300px;
}

/* Witraze page */
.pageHeader {
    text-align: center;
    margin: 70px auto 50px;
    font-size: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #B56437;
    border-bottom: 1px solid var(--gold);
    padding-bottom: 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: -moz-max-content;
}

.pageContent__witraze {
    margin: 60px auto 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: clamp(200px, 60vw, 2000px);
}

.pageContent__witraze__section {
    position: relative;
    width: 45%;
    overflow: hidden;
    height: 450px;
    border: 1px solid rgba(255, 255, 255, .75);
}

.pageContent__witraze__img {
    width: 100%;
}

.pageContent__witraze__section__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgba(0, 0, 0, .8);
    padding: 10%;
}

.pageContent__witraze__section__header {
    text-align: center;
    font-size: 28px;
}

.pageContent__witraze__section__desc {
    text-align: center;
    margin: 25px 0 40px;
    font-size: 13px;
}

.button--witraze {
    width: 250px;
    height: 75px;
    border-color: #B56437;
}

.pageContent__witrazeGrid {
    width: clamp(200px, 85vw, 2300px);
    width: var(--siteWidth);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 50px auto;
}

/* Witraze subpage */
.pageContent__witrazeGrid__item {
    width: 30%;
    height: 450px;
    margin-bottom: 40px;
    overflow: hidden;
    position: relative;
    border: 1px solid rgba(255, 255, 255, .8);
    display: flex;
    justify-content: center;
    align-items: center;
}

.pageContent__witrazeGrid__item .pageContent__witraze__section__content {
    opacity: 0;
    transition: .5s all ease-in-out;
}

.pageContent__witrazeGrid__item .pageContent__witraze__section__content:hover {
    opacity: 1;
}

/* References */
.pageContent__witrazeGrid--references .pageContent__witrazeGrid__item {
    height: auto;
}

.pageContent__witrazeGrid--references .pageContent__witraze__img {
    width: 100%;
    height: auto;
}

/* Art gallery */
.pageContent__artGallery {
    position: relative;
    margin-bottom: 220px;
}

.pageContent__artGallery__desc {
    position: absolute;
    top: 20%;
    left: clamp(100px, 40%, 900px);
    width: 40%;
}

.pageContent__artGallery__desc p {
    margin-top: 15px;
}

.pageContent__artGallery__header {
    font-size: 36px;
    font-weight: 400;
    margin-bottom: 30px;
}

.pageContent__artGallery__images {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 120%;
    margin: 90px 0;
}

.pageContent__artGallery__images__img {
    width: 30%;
    height: auto;
}

/* About us */
.aboutUs__section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 80px;
}

.aboutUs__section--end {
    justify-content: flex-end;
}

.aboutUs__section--center {
    justify-content: center;
}

.aboutUs__section__header {
    font-size: 32px;
    font-weight: 400;
    margin-bottom: 50px;
}

.aboutUs__section__content {
    width: 45%;
    margin-left: 50px;
}

.aboutUs__section__content p {
    margin: 30px 0;
}

.aboutUs__section--end .aboutUs__section__content {
    margin-right: 80px;
}

.aboutUs__section--end .aboutUs__section__header,
.aboutUs__section--end .aboutUs__section__content p {
    text-align: right;
}

.aboutUs__section__buttons {
    display: flex;
    align-items: center;
    margin-top: -30px;
}

.aboutUs__section__buttons .button--aboutUs {
    width: 300px;
    height: 60px;
    font-size: 20px;
    margin-right: 30px;
}

.aboutUs__section__img--1 {
    width: 30%;
    height: auto;
}

.aboutUs__section__img--2 {
    width: 35%;
    height: auto;
}

.aboutUs__section__img--3 {
    width: 25%;
    height: auto;
}

/* Technologies */
.technologies__section {
    width: clamp(200px, 85vw, 2300px);
    width: var(--siteWidth);
    margin: 40px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.technologies__section .aboutUs__section__content {
    width: 100%;
}

.aboutUs__section__content--marginRight {
    margin-right: 50px;
    margin-left: 0;
}

.pageContent--technologies {
    margin-bottom: 100px;
}

.technologies__section__img {
    width: 50%;
}

/* Privacy policy and terms of service */
.pageContent__article {
    width: clamp(200px, 85vw, 2300px);
    width: var(--siteWidth);
    margin: 40px auto;
}

.pageContent__article p {
    margin: 20px 0;
    text-align: justify;
}

/* Top bar shop */
.topBarShop {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: clamp(200px, 85vw, 2300px);
    width: var(--siteWidth);
    margin: 0 auto 20px;
    padding: 20px 0;
    border-bottom: 1px solid rgba(255, 255, 255, .25);
}

.topBarShop__header {
    font-weight: 400;
}

.topBarShop__header a {
    font-family: 'Cormorant', serif;
    opacity: .9;
}

.topBarShop__header a:hover {
    opacity: 1;
}

.topBarShop__cart {
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.topMenuShopMobile {
    width: clamp(200px, 85vw, 2300px);
    width: var(--siteWidth);
    margin: 20px auto;
    justify-content: space-between;
    align-items: center;
}

.topMenuShopMobile .topMenu__mobile {
    width: 100%;
}

/* Shop */
.shop {
    width: clamp(200px, 85vw, 2300px);
    width: var(--siteWidth);
    margin: 30px auto 150px;
}

.shop__top {
    width: clamp(200px, 85vw, 2300px);
    width: var(--siteWidth);
    margin: 10px auto 30px;
}

.shop__top .shop__top__link {
    color: rgba(255, 255, 255, .5) !important;
    font-size: 13px;
    text-decoration: underline;
    transition: .3s all ease;
}

.shop__top .shop__top__link:hover {
    color: rgba(255, 255, 255, .8) !important;
}

.shop__main {
    margin: 40px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.shop__categories {
    flex-basis: 20%;
    border: 1px solid #B56437;
    border: 1px solid var(--gold);
}

.shop__products {
    flex-basis: 75%;
}

.shop__categories__header {
    text-align: center;
    font-size: 26px;
    font-weight: 400;
    border-bottom: 1px solid rgba(255, 255, 255, .5);
    padding: 15px 0;
    margin-bottom: 15px;
}

.shop__categories__header a {
    font-family: 'Cormorant', serif;
}

.shop__categories__list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.shop__categories__list__item {
    width: 80%;
    text-align: center;
    padding: 25px 0;
    border-bottom: 1px solid rgba(255, 255, 255, .25);
}

.shop__categories__list__item__link {
    transition: .3s all ease-in-out;
}

.shop__categories__list__item__link:hover {
    color: #B56437 !important;
    color: var(--gold) !important;
}

.shop__products__header {
    font-size: 32px;
    border-bottom: 1px solid rgba(255, 255, 255, .25);
    padding-bottom: 10px;
    font-weight: 400;
}

.shop__products__products {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.shop__products__singleProduct {
    width: 23%;
    margin: 30px 0;
}

.shop__products__singleProduct__imgWrapper {
    width: 100%;
    height: 350px;
    overflow: hidden;
}

.shop__products__singleProduct__img {
    min-width: 100%;
    min-height: 100%;
    max-width: 110%;
    max-height: 110%;
    transition: .5s all ease-in-out;
}

.shop__products__singleProduct__title {
    font-weight: 400;
    text-align: center;
    font-size: 28px;
    margin: 25px 0 15px;
}

.shop__products__singleProduct__price {
    text-align: center;
    font-size: 26px;
    font-weight: 400;
    color: #B56437;
    color: var(--gold);
    font-family: 'Montserrat', sans-serif;
}

.button--addToCart {
    margin: 25px auto;
    width: 100%;
    height: 60px;
}

.shop__products__singleProduct:hover .shop__products__singleProduct__img {
    -webkit-filter: brightness(.5);
            filter: brightness(.5);
}

/* Single product */
.single {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: clamp(200px, 85vw, 2300px);
    width: var(--siteWidth);
    margin: 40px auto;
}

.single__product {
    width: 75%;
}

.dedicationInput {
    width: 100%;
    height: 100px;
    padding: 15px;
    font-family: 'Montserrat', sans-serif;
    color: #efefef;
    resize: none;
    background: transparent;
    border: 1px solid #B56437;
    border: 1px solid var(--gold);
}

.shop__products__header {
    position: relative;
}

.shop__products__header__price {
    color: #B56437;
    color: var(--gold);
    font-family: 'Montserrat', sans-serif;
    position: absolute;
    right: 0;
    top: 0;
}

.single__product__main {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 40px 0;
}

.single__product__main__imgWrapper {
    width: 35%;
    height: 500px;
    overflow: hidden;
    position: relative;
}

.single__product__main__imgWrapper img {
    /*width: 100% !important;*/
}

.single__product__main__imgOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    opacity: 0;
    transition: .4s all ease-in-out;
}

.single__product__main__imgWrapper:hover .single__product__main__imgOverlay {
    opacity: 1;
}

.single__product__main__imgOverlay__img {
    width: 40px;
    height: auto;
}

.single__product__main__imgWrapper img {
    min-width: 100%;
    min-height: 100%;
}

.single__product__main__content {
    width: 60%;
}

.single__product__main__spec {
    padding: 30px;
    background: #141414;
}

.single__product__main__spec__header {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    padding-bottom: 10px;
    font-size: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, .5);
    margin-bottom: 30px;
}

.single__product__main__spec__desc {
    font-size: 14px;
}

.single__product__main__spec__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, .25);
    margin-bottom: 20px;
}

.single__product__main__spec__key {
    color: rgba(255, 255, 255, .55);
}

.single__product__main__spec__header:last-of-type {
    margin-top: 40px;
}

/* Cart */
.cart {
    margin: 40px auto 100px;
    width: clamp(200px, 85vw, 2300px);
    width: var(--siteWidth);
}

.cart__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid rgba(255, 255, 255, .25);
    margin: 20px 0;
}

.cart__item__imgWrapper {
    width: 200px;
    height: 200px;
    overflow: hidden;
}

.cart__item__img {
    min-width: 100%;
    min-height: 100%;
    max-width: 200%;
}

.cart__item__prop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cart__item__key,
.cart__item__value {
    text-align: center;
}

.cart__item__key {
    font-size: 14px;
    color: rgba(255, 255, 255, .5);
    padding-bottom: 15px;
}

.cart__item__value {
    font-size: 16px;
}

.cart__item__value--trash {
    background: transparent;
    border: none;
    outline: none;
    opacity: .75;
    transition: .2s all;
}

.cart__item__value--trash:hover {
    opacity: 1;
}

.cart__sum {
    font-size: 32px;
    font-weight: 400;
    text-align: right;
    margin: 10px 0 30px;
}

.cart__sum__price {
    padding-left: 25px;
    font-family: 'Montserrat', sans-serif;
    color: #B56437;
    color: var(--gold);
}

.cart__item__value--price {
    color: #B56437;
    color: var(--gold);
    font-size: 18px;
}

.cart__item__value input {
    border: none;
    background: transparent;
    width: 25px;
    height: 30px;
    color: #fff;
}

.cart__item__value--input {
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #B56437;
    border-bottom: 1px solid var(--gold);
    padding-bottom: 8px;
}

/* Remove arrows from number input type - Chrome, Safari, Edge, Opera */
.cart input::-webkit-outer-spin-button,
.cart input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.cart__item__input__btn {
    background: transparent;
    border: none;
    outline: none;
    width: 8px;
    height: auto;
}

.cart__item__input__btn__img {
    width: 100%;
    height: auto;
}

.cart__item__input__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.cart__item__input__btn:last-of-type .cart__item__input__btn__img {
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg);
}

.cart__item__mobileRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cart__item__mobileRow > * {
    margin: 0 5vw;
}

/* Shipping and payment */
.shippingAndPayment {
    width: clamp(200px, 85vw, 2300px);
    width: var(--siteWidth);
    margin: 50px auto;
}

.error {
    display: block;
    color: red;
    font-size: 13px;
    padding-bottom: 30px;
}

.label--checkbox {
    font-size: 13px;
    margin-top: 50px;
}

.label--checkbox input {
    margin-right: 10px;
}

.label--checkbox a {
    opacity: .9;
    font-size: 13px;
}

.label--checkbox a:hover {
    opacity: 1;
}

.shippingAndPayment__sum {
    font-weight: 400;
    font-size: 26px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.shippingAndPayment__sum__price {
    padding-left: 30px;
    font-weight: 400;
    color: #B56437;
    color: var(--gold);
    padding-top: 5px;
}

.shippingAndPayment__form {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.shippingAndPayment__form__section {
    width: 45%;
    margin-top: 40px;
}

.shippingAndPayment__smallHeader {
    font-size: 16px;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, .5);
    margin-bottom: 20px;
}

.shippingAndPayment__dataForm {
    width: 100%;
}

.input {
    background: #131313;
    border: 1px solid #B56437;
    border: 1px solid var(--gold);
    height: 100%;
    padding: 0 15px;
    width: 100%;
    color: #fff;
}

.input--error {
    border-color: red;
}

.input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, .5);
}

.input:-ms-input-placeholder {
    color: rgba(255, 255, 255, .5);
}

.input::placeholder {
    color: rgba(255, 255, 255, .5);
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.shippingAndPayment__form label {
    display: block;
    height: 50px;
    margin-bottom: 20px;
}

.label--name {
    width: 38%;
}

.label--surname {
    width: 60%;
}

.label--postalCode {
    width: 33%;
}

.label--city {
    width: 65%;
}

.label--street {
    width: 50%;
}

.label--building, .label--flat {
    width: 23%;
}

.shippingAndPayment__form .label--textarea {
    height: auto;
}

.input--textarea {
    padding: 15px;
    height: 150px;
    resize: none;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
}

.label--button {
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    height: 25px !important;
    margin: 0;
    cursor: pointer;
}

.button--check {
    width: 20px;
    height: 20px;
    background: transparent;
    border: 1px solid #B56437;
    border: 1px solid var(--gold);
    border-radius: 50%;
    margin-right: 15px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.shippingAndPayment__smallHeader--marginTop {
    margin-top: 50px;
}

.button--shippingAndPayment {
    margin-top: 0;
}

.button--checked {
    display: block;
    width: 50%;
    height: 50%;
    background: #B56437;
    background: var(--gold);
    border-radius: 50%;
}

/* Add to cart modal */
.modalWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
    opacity: 0;
    transition: .6s opacity;
}

.addToCartModal {
    position: relative;
    z-index: 10000;
    width: 70%;
    max-width: 800px;
    height: 500px;
    background: #131313;
    border: 1px solid #B56437;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.addToCartModal__close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 20px;
    background: transparent;
    border: none;
    outline: none;
}

.addToCartModal__close__img {
    width: 100%;
}

.addToCartModal__cartIcon {
    width: 200px;
    height: auto;
    margin-top: -20px;
}

.addToCartModal__header {
    text-align: center;
    font-weight: 400;
    font-size: 17px;
    margin: 25px 0 45px;
    font-family: 'Montserrat', sans-serif;
}

.addToCartModal__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.button--addToCartModal {
    width: 300px;
    height: 50px;
    margin: 0 15px;
    font-family: 'Montserrat', sans-serif;
}

/* Empty cart */
.emptyCart__header {
    margin: 50px 0 20px;
    font-size: 24px;
    font-weight: 400;
}

.button--emptyCart {
    margin-top: 50px;
    width: 300px;
    height: 50px;
}

/* Ty page */
.ty {
    padding: 70px 20px;
}

.ty__header, .ty__subheader {
    text-align: center;
    font-weight: 400;
    font-size: 28px;
}

.ty__subheader {
    margin: 15px 0 40px;
}

.button--ty {
    width: 400px;
    height: 60px;
    margin: 20px auto;
}

/* Admin */
.loginPage .label {
    color: #fff;
}

.input--adminLogin {
    height: 40px;
}

.button--loginForm {
    font-family: 'Open sans', sans-serif;
    font-weight: 400;
}

.button--loginForm:hover {
    letter-spacing: 0 !important;
}

/* Pages */
article.pageContent__article * {
    color: #fff !important;
    background: transparent !important;
}

.d-900 {
    display: none;
}

.ue {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    z-index: 100;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ue .img {
    height: 60px;
    width: auto;
    margin: 0 20px;
}

@media(max-width: 768px) {
    .ue {
        height: 60px;
    }

    .ue .img {
        height: 20px;
        width: auto;
        margin: 0 10px;
    }
}

@media(min-width: 1600px) {
    .pageContent__artGallery__bigImage {
        margin-bottom: -25px;
    }

    .pageContent__artGallery__desc {
        top: unset;
        bottom: -70px;
    }

    .pageContent__artGallery__images {
        margin-bottom: 0;
    }
}

@media(max-width: 1800px) {
    .aboutUs__content {
        margin-left: clamp(50px, 25%, 340px);
        max-width: 40%;
    }

    .aboutUs__header--technologies {
        margin-left: 50%;
    }

    .technology--1 {
        margin-left: 50%;
    }

    .pageContent__artGallery__desc {
        left: 45%;
    }
}

@media(max-width: 1600px) {
    .aboutUs__content {
        margin-left: 27%;
    }

    .aboutUs__text {
        font-size: 15px;
    }

    .technology--2 {
        margin-left: 10%;
    }

    .img--technologies3 {
        -webkit-transform: scale(.7);
                transform: scale(.7);
        -webkit-transform-origin: top left;
                transform-origin: top left;
    }

    @media(min-width: 996px) {
        .img--technologies3 {
            -webkit-transform: scale(.7) !important;
                    transform: scale(.7) !important;
        }
    }

    .pageContent__artGallery__bigImage {
        -webkit-transform: scale(.75) !important;
                transform: scale(.75) !important;
        -webkit-transform-origin: top left;
                transform-origin: top left;
    }

    .shop__products__singleProduct {
        width: 30%;
    }
}

@media(max-width: 1400px) {
    @media(min-width: 768px) {
        .aboutUs__img--1, .aboutUs__img--2 {
            -webkit-transform: scale(.7) !important;
                    transform: scale(.7) !important;
        }

        .aboutUs__content {
            margin-left: 25%;
        }
    }

    @media(min-width: 996px) {
        .img--technologies1, .img--technologies2  {
            -webkit-transform: scale(.7) !important;
                    transform: scale(.7) !important;
        }
    }

    .heroCarousel__header {
        font-size: 48px;
    }

    .heroCarousel__subheader {
        font-size: 21px;
    }

    .button--landing {
        height: 70px;
        width: 350px;
        font-size: 24px;
    }

    .topMenu__link {
        font-size: 13px;
    }

    .aboutUs__img--1 {
        -webkit-transform-origin: top left;
                transform-origin: top left;
    }

    .aboutUs__img--2 {
        -webkit-transform-origin: top right;
                transform-origin: top right;
    }

    .img--technologies1, .img--technologies2, .img--technologies3 {
        -webkit-transform: scale(.7);
                transform: scale(.7);
        -webkit-transform-origin: top left;
                transform-origin: top left;
    }

    .img--technologies2 {
        -webkit-transform-origin: top right;
                transform-origin: top right;
    }

    .technology__name {
        font-size: 32px;
    }

    .technology {
        max-width: 400px;
    }

    .section--technologies {
        margin-bottom: 100px;
    }

    .pageContent__witraze {
        width: var(--siteWidth);
    }

    .pageContent__witrazeGrid__item {
        width: 32%;
        margin-bottom: 20px;
    }

    .pageContent__artGallery__desc {
        top: 5%;
    }

    .pageContent__artGallery {
        margin-bottom: 100px;
    }

    .shop__products__singleProduct__title {
        font-size: 24px;
    }

    .shop__products__singleProduct__price {
        font-size: 21px;
    }

    .shippingAndPayment__form__section {
        width: 48%;
    }
}

@media(max-width: 1200px) {
    .topBar__logoLink__img {
        width: 400px;
    }

    .topBar__shop {
        font-size: 10px;
    }

    .contact__header {
        font-size: 32px;
    }

    .contact__item__text {
        font-size: 17px;
    }

    .contact__item__icon {
        width: 20px;
    }

    .aboutUs__img--2 {
        display: none;
    }

    .aboutUs__content {
        max-width: 65%;
    }

    .section--technologies {
        margin-top: 100px;
    }

    .img--technologies2 {
        top: 600px;
    }

    .img--technologies3 {
        left: 0;
    }

    .button--technology {
        width: 250px;
        height: 50px;
        font-size: 17px;
    }

    .pageContent__witrazeGrid__item {
        width: 48%;
        margin-bottom: 35px;
    }

    .pageContent__artGallery__bigImage {
        -webkit-transform: scale(.5) !important;
                transform: scale(.5) !important;
    }

    .aboutUs__section__content p {
        font-size: 14px;
        margin: 25px 0;
    }

    .aboutUs__section__header {
        font-size: 26px;
        margin-bottom: 20px;
    }

    .aboutUs__section__content {
        width: 55%;
    }

    .shop__categories {
        width: 30%;
        flex-basis: 30%;
    }

    .shop__products {
        width: 65%;
        flex-basis: 65%;
    }

    .shop__products__singleProduct {
        width: 48%;
    }

    .cart__item__imgWrapper {
        width: 150px;
        height: 150px;
    }

    .cart__item__key {
        font-size: 12px;
    }

    .cart__item__value {
        font-size: 14px;
    }

    .cart__sum {
        font-size: 24px;
    }

    .cart__item {
        flex-direction: column;
    }

    .cart__item > * {
        margin-bottom: 30px;
    }

    .cart__item__key {
        padding-bottom: 10px;
    }

    .button--addToCartModal {
        width: 200px;
        font-size: 14px;
    }
}

@media(max-width: 996px) {
    .d-desktop {
        display: none;
    }

    .d-mobile {
        display: block;
    }

    .topBar__language__inner--second {
        right: unset;
        left: 0;
        bottom: -35px;
    }

    .footer {
        flex-direction: column;
        align-items: center;
    }

    .footer__section {
        margin-bottom: 30px;
        width: 60%;
    }

    .footerHeader {
        text-align: center;
    }

    .footerHeader::after {
        display: none;
    }

    .footer__list__item {
        text-align: center;
    }

    .footer__bottom {
        margin-top: 0;
    }

    .heroCarousel__header, .heroCarousel__subheader {
        text-align: center;
    }

    .heroCarousel__header {
        font-size: 36px;
        margin-top: 25%;
    }

    .heroCarousel__subheader {
        font-size: 16px;
    }

    .button--landing {
        margin-left: auto;
        margin-right: auto;
    }

    .heroCarousel__buttons {
        right: unset;
        bottom: 12px;
        top: unset;
        left: 5%;
    }

    .heroCarousel__button {
        width: 18px;
        height: 18px;
    }

    .heroCarousel__button--selected {
        width: 10px;
        height: 10px;
    }

    .heroCarousel__arrows {
        right: 5%;
    }

    .topMenu__mobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .topMenu__mobile:first-of-type .topMenu__mobile__icon {
        margin-right: 10px;
    }

    .topMenu__mobile:last-of-type .topMenu__mobile__icon {
        margin-left: 10px;
    }

    .topMenu__mobile__item {
        background: transparent;
        border: none;
        outline: none;
        font-family: 'Montserrat', sans-serif;
        font-size: 10px;
        text-transform: uppercase;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .topMenu {
        border-bottom: none;
        border-top: 1px solid rgba(255, 255, 255, .5);
        padding-top: 25px;
        margin-top: -30px;
    }

    .flag {
        margin-right: 0;
    }

    .topBar__language__inner {
        border-bottom: none;
    }

    .portfolioItem {
        width: 48%;
    }

    .aboutUs__content {
        margin-left: 40%;
    }

    .technologies {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .img--technologies1, .img--technologies2, .img--technologies3 {
        position: relative;
        top: unset;
        left: unset;
        right: unset;
        -webkit-transform: none;
                transform: none;
        width: 100%;
        max-width: 600px;
        margin: 45px 0;
    }

    .img--technologies1 {
        margin-top: 10px;
    }

    .aboutUs__header--technologies {
        margin-left: 0;
        text-align: center;
        order: 1;
    }

    .img--technologies1 {
        order: 2;
    }

    .technology {
        margin: 0;
    }

    .technology--1 {
        order: 3;
    }

    .img--technologies2 {
        order: 4;
    }

    .technology--2 {
        order: 5;
    }

    .img--technologies3 {
        order: 6;
    }

    .technology--3 {
        order: 7;
    }

    .mobileMenu {
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        height: 100vh;
        background: #000;
        z-index: 15;
        -webkit-transform: scaleX(0);
                transform: scaleX(0);
        -webkit-transform-origin: top right;
                transform-origin: top right;
        transition: .5s all;
        display: flex !important;
        justify-content: center;
        align-items: center;
    }

    .mobileMenu__close {
        background: transparent;
        width: 25px;
        height: auto;
        border: none;
        outline: none;
        position: absolute;
        top: 25px;
        right: 25px;
        opacity: 0;
        transition: .3s all;
    }

    .mobileMenu__close__img {
        width: 100%;
        height: auto;
    }

    .mobileMenu__list {
        list-style-type: none;
        opacity: 0;
        width: 100%;
        transition: .3s all;
    }

    .mobileMenu__list__item {
        width: 80%;
        text-align: center;
        padding: 10px 0;
        margin: 10px auto;
        text-transform: uppercase;
        font-size: 14px;
        border-bottom: 1px solid var(--gold);
    }

    .pageContent__witraze__section {
        width: 48%;
    }

    .button--aboutUs {
        font-size: 16px !important;
    }

    .technologies__section {
        flex-direction: column;
    }

    .technologies__section > * {
        width: 100%;
        margin-bottom: 30px;
    }

    .technologies__section .aboutUs__section__content {
        margin-left: 0;
    }

    .technologies__section--revert .technologies__section__img {
        order: 1;
    }

    .technologies__section--revert .aboutUs__section__content {
        order: 2;
        margin-right: 0;
    }

    .heroCarousel__content {
        width: 100%;
        padding: 0 5vw;
    }

    .shop__products__singleProduct {
        width: 100%;
    }

    .shippingAndPayment__form {
        flex-direction: column;
    }

    .shippingAndPayment__form__section {
        width: 100%;
        margin-bottom: 0;
    }

    .d-900 {
        display: block;
    }

    .d-900-flex {
        display: flex !important;
    }

    .single {
        flex-direction: column;
    }

    .single__product {
        width: 100%;
        margin-top: 40px;
    }

    .single__product__main {
        flex-direction: column;
    }

    .single__product__main__imgWrapper, .single__product__main__content {
        width: 100%;
    }

    .single__product__main__imgWrapper {
        height: auto;
        margin-bottom: 30px;
    }
}

@media(max-width: 768px) {
    .contact {
        flex-direction: column;
        margin-bottom: 25px;
    }

    .contact__content, .contact__map {
        width: 100%;
    }

    .contact__map {
        margin-bottom: 30px;
    }

    .aboutUs__img--1 {
        position: relative;
        left: unset;
        top: unset;
        display: block;
        -webkit-transform: none;
                transform: none;
    }

    .aboutUs__content {
        margin: auto;
        max-width: 100%;
    }

    .footer__list__item__link {
        font-size: 13px;
    }

    .pageContent__witraze {
        flex-direction: column;
    }

    .pageContent__witraze__section {
        width: 100%;
        max-width: 500px;
        margin-bottom: 30px;
    }

    .pageContent__witrazeGrid__item {
        width: 100%;
    }

    .pageContent__artGallery__bigImage {
        display: block;
        margin: 30px auto;
        -webkit-transform: none !important;
                transform: none !important;
        width: var(--siteWidth);
    }

    .pageContent__artGallery__desc {
        position: relative;
        left: 0;
        width: var(--siteWidth);
        margin: auto;
    }

    .pageContent__artGallery__images {
        width: 100%;
        margin-top: 30px;
    }

    .aboutUs__section {
        flex-direction: column;
        width: var(--siteWidth);
        margin: 20px auto;
    }

    .aboutUs__section > * {
        width: 100%;
        margin-bottom: 40px;
    }

    .aboutUs__section .aboutUs__section__content {
        margin-left: 0;
        margin-right: 0;
    }

    .aboutUs__section__header,
    .aboutUs__section__content p {
        text-align: left !important;
    }

    .aboutUs__section__content--2 {
        order: 2;
    }

    .aboutUs__section__img--2 {
        order: 1;
    }

    .pageContent__article p {
        font-size: 14px;
    }

    .shop__main {
        flex-direction: column;
    }

    .shop__categories, .shop__products {
        flex-basis: 100%;
        width: 100%;
    }

    .shop__products {
        margin-top: 40px;
    }

    .shop {
        margin-bottom: 40px;
    }

    .shop__products__header {
        font-size: 24px;
    }

    .addToCartModal {
        width: 100%;
        height: 100%;
        border: none;
    }

    .addToCartModal__buttons {
        flex-wrap: wrap;
    }

    .button--addToCartModal {
        width: 150px;
        font-size: 12px;
        margin: 8px;
    }

    .addToCartModal__cartIcon {
        width: 120px;
    }

    .ty__header, .ty__subheader {
        font-size: 21px;
    }

    .button--ty {
        width: 100%;
        max-width: 400px;
    }

    .shop__categories__list__item {
        font-size: 14px;
        padding: 15px 0;
    }
}

@media(max-width: 576px) {
    .footer__section {
        width: 100%;
    }

    .footerHeader {
        font-size: 18px;
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 12px;
    }

    .footer__list__item {
        margin: 5px 0;
    }

    .footer__list--flex {
        flex-direction: column;
        align-items: center;
    }

    .topBar__logoLink__img {
        width: 100%;
    }

    .section--portfolio {
        margin-bottom: 60px;
    }

    .portfolioSection {
        flex-direction: column;
    }

    .portfolioItem {
        width: 100%;
        margin-bottom: 25px;
    }

    .button--process, .button--aboutUs, .button--portfolio {
        width: 100%;
        font-size: 15px;
    }

    .button--portfolio {
        margin-top: 10px;
    }

    .creationProcess {
        margin-top: 15px;
    }

    .creationProcess__desc,
    .technology__desc
    {
        font-size: 14px;
    }

    .aboutUs__img--1 {
        width: 100%;
    }

    .button--landing {
        max-width: 70%;
        font-size: 16px;
    }

    .heroCarousel__header {
        font-size: 27px;
    }

    .heroCarousel__subheader {
        font-size: 14px;
    }

    .topBar__facebookLink {
        font-size: 10px;
    }

    .pageContent__witrazeGrid__item {
        height: 300px;
    }

    .pageHeader {
        font-size: 24px;
    }

    .button--aboutUs {
        font-size: 13px !important;
        text-align: center;
    }

    .button--aboutUs:first-of-type {
        margin-right: 15px;
    }

    .button--aboutUs:last-of-type {
        margin-right: 0;
    }

    .pageContent--technologies {
        margin-bottom: 0;
    }

    .cart__sum {
        font-size: 18px;
    }

    /* TMP */
    .heroCarousel__image__img {
        left: -760px;
    }

    .heroCarousel__image__img:nth-of-type(2) {
        left: -300px;
    }

    .heroCarousel__image__img:last-of-type {
        left: -350px;
    }
}

