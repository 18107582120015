@media(min-width: 1600px) {
    .pageContent__artGallery__bigImage {
        margin-bottom: -25px;
    }

    .pageContent__artGallery__desc {
        top: unset;
        bottom: -70px;
    }

    .pageContent__artGallery__images {
        margin-bottom: 0;
    }
}

@media(max-width: 1800px) {
    .aboutUs__content {
        margin-left: clamp(50px, 25%, 340px);
        max-width: 40%;
    }

    .aboutUs__header--technologies {
        margin-left: 50%;
    }

    .technology--1 {
        margin-left: 50%;
    }

    .pageContent__artGallery__desc {
        left: 45%;
    }
}

@media(max-width: 1600px) {
    .aboutUs__content {
        margin-left: 27%;
    }

    .aboutUs__text {
        font-size: 15px;
    }

    .technology--2 {
        margin-left: 10%;
    }

    .img--technologies3 {
        transform: scale(.7);
        transform-origin: top left;
    }

    @media(min-width: 996px) {
        .img--technologies3 {
            transform: scale(.7) !important;
        }
    }

    .pageContent__artGallery__bigImage {
        transform: scale(.75) !important;
        transform-origin: top left;
    }

    .shop__products__singleProduct {
        width: 30%;
    }
}

@media(max-width: 1400px) {
    @media(min-width: 768px) {
        .aboutUs__img--1, .aboutUs__img--2 {
            transform: scale(.7) !important;
        }

        .aboutUs__content {
            margin-left: 25%;
        }
    }

    @media(min-width: 996px) {
        .img--technologies1, .img--technologies2  {
            transform: scale(.7) !important;
        }
    }

    .heroCarousel__header {
        font-size: 48px;
    }

    .heroCarousel__subheader {
        font-size: 21px;
    }

    .button--landing {
        height: 70px;
        width: 350px;
        font-size: 24px;
    }

    .topMenu__link {
        font-size: 13px;
    }

    .aboutUs__img--1 {
        transform-origin: top left;
    }

    .aboutUs__img--2 {
        transform-origin: top right;
    }

    .img--technologies1, .img--technologies2, .img--technologies3 {
        transform: scale(.7);
        transform-origin: top left;
    }

    .img--technologies2 {
        transform-origin: top right;
    }

    .technology__name {
        font-size: 32px;
    }

    .technology {
        max-width: 400px;
    }

    .section--technologies {
        margin-bottom: 100px;
    }

    .pageContent__witraze {
        width: var(--siteWidth);
    }

    .pageContent__witrazeGrid__item {
        width: 32%;
        margin-bottom: 20px;
    }

    .pageContent__artGallery__desc {
        top: 5%;
    }

    .pageContent__artGallery {
        margin-bottom: 100px;
    }

    .shop__products__singleProduct__title {
        font-size: 24px;
    }

    .shop__products__singleProduct__price {
        font-size: 21px;
    }

    .shippingAndPayment__form__section {
        width: 48%;
    }
}

@media(max-width: 1200px) {
    .topBar__logoLink__img {
        width: 400px;
    }

    .topBar__shop {
        font-size: 10px;
    }

    .contact__header {
        font-size: 32px;
    }

    .contact__item__text {
        font-size: 17px;
    }

    .contact__item__icon {
        width: 20px;
    }

    .aboutUs__img--2 {
        display: none;
    }

    .aboutUs__content {
        max-width: 65%;
    }

    .section--technologies {
        margin-top: 100px;
    }

    .img--technologies2 {
        top: 600px;
    }

    .img--technologies3 {
        left: 0;
    }

    .button--technology {
        width: 250px;
        height: 50px;
        font-size: 17px;
    }

    .pageContent__witrazeGrid__item {
        width: 48%;
        margin-bottom: 35px;
    }

    .pageContent__artGallery__bigImage {
        transform: scale(.5) !important;
    }

    .aboutUs__section__content p {
        font-size: 14px;
        margin: 25px 0;
    }

    .aboutUs__section__header {
        font-size: 26px;
        margin-bottom: 20px;
    }

    .aboutUs__section__content {
        width: 55%;
    }

    .shop__categories {
        width: 30%;
        flex-basis: 30%;
    }

    .shop__products {
        width: 65%;
        flex-basis: 65%;
    }

    .shop__products__singleProduct {
        width: 48%;
    }

    .cart__item__imgWrapper {
        width: 150px;
        height: 150px;
    }

    .cart__item__key {
        font-size: 12px;
    }

    .cart__item__value {
        font-size: 14px;
    }

    .cart__sum {
        font-size: 24px;
    }

    .cart__item {
        flex-direction: column;
    }

    .cart__item > * {
        margin-bottom: 30px;
    }

    .cart__item__key {
        padding-bottom: 10px;
    }

    .button--addToCartModal {
        width: 200px;
        font-size: 14px;
    }
}

@media(max-width: 996px) {
    .d-desktop {
        display: none;
    }

    .d-mobile {
        display: block;
    }

    .topBar__language__inner--second {
        right: unset;
        left: 0;
        bottom: -35px;
    }

    .footer {
        flex-direction: column;
        align-items: center;
    }

    .footer__section {
        margin-bottom: 30px;
        width: 60%;
    }

    .footerHeader {
        text-align: center;
    }

    .footerHeader::after {
        display: none;
    }

    .footer__list__item {
        text-align: center;
    }

    .footer__bottom {
        margin-top: 0;
    }

    .heroCarousel__header, .heroCarousel__subheader {
        text-align: center;
    }

    .heroCarousel__header {
        font-size: 36px;
        margin-top: 25%;
    }

    .heroCarousel__subheader {
        font-size: 16px;
    }

    .button--landing {
        margin-left: auto;
        margin-right: auto;
    }

    .heroCarousel__buttons {
        right: unset;
        bottom: 12px;
        top: unset;
        left: 5%;
    }

    .heroCarousel__button {
        width: 18px;
        height: 18px;
    }

    .heroCarousel__button--selected {
        width: 10px;
        height: 10px;
    }

    .heroCarousel__arrows {
        right: 5%;
    }

    .topMenu__mobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .topMenu__mobile:first-of-type .topMenu__mobile__icon {
        margin-right: 10px;
    }

    .topMenu__mobile:last-of-type .topMenu__mobile__icon {
        margin-left: 10px;
    }

    .topMenu__mobile__item {
        background: transparent;
        border: none;
        outline: none;
        font-family: 'Montserrat', sans-serif;
        font-size: 10px;
        text-transform: uppercase;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .topMenu {
        border-bottom: none;
        border-top: 1px solid rgba(255, 255, 255, .5);
        padding-top: 25px;
        margin-top: -30px;
    }

    .flag {
        margin-right: 0;
    }

    .topBar__language__inner {
        border-bottom: none;
    }

    .portfolioItem {
        width: 48%;
    }

    .aboutUs__content {
        margin-left: 40%;
    }

    .technologies {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .img--technologies1, .img--technologies2, .img--technologies3 {
        position: relative;
        top: unset;
        left: unset;
        right: unset;
        transform: none;
        width: 100%;
        max-width: 600px;
        margin: 45px 0;
    }

    .img--technologies1 {
        margin-top: 10px;
    }

    .aboutUs__header--technologies {
        margin-left: 0;
        text-align: center;
        order: 1;
    }

    .img--technologies1 {
        order: 2;
    }

    .technology {
        margin: 0;
    }

    .technology--1 {
        order: 3;
    }

    .img--technologies2 {
        order: 4;
    }

    .technology--2 {
        order: 5;
    }

    .img--technologies3 {
        order: 6;
    }

    .technology--3 {
        order: 7;
    }

    .mobileMenu {
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        height: 100vh;
        background: #000;
        z-index: 15;
        transform: scaleX(0);
        transform-origin: top right;
        transition: .5s all;
        display: flex !important;
        justify-content: center;
        align-items: center;
    }

    .mobileMenu__close {
        background: transparent;
        width: 25px;
        height: auto;
        border: none;
        outline: none;
        position: absolute;
        top: 25px;
        right: 25px;
        opacity: 0;
        transition: .3s all;
    }

    .mobileMenu__close__img {
        width: 100%;
        height: auto;
    }

    .mobileMenu__list {
        list-style-type: none;
        opacity: 0;
        width: 100%;
        transition: .3s all;
    }

    .mobileMenu__list__item {
        width: 80%;
        text-align: center;
        padding: 10px 0;
        margin: 10px auto;
        text-transform: uppercase;
        font-size: 14px;
        border-bottom: 1px solid var(--gold);
    }

    .pageContent__witraze__section {
        width: 48%;
    }

    .button--aboutUs {
        font-size: 16px !important;
    }

    .technologies__section {
        flex-direction: column;
    }

    .technologies__section > * {
        width: 100%;
        margin-bottom: 30px;
    }

    .technologies__section .aboutUs__section__content {
        margin-left: 0;
    }

    .technologies__section--revert .technologies__section__img {
        order: 1;
    }

    .technologies__section--revert .aboutUs__section__content {
        order: 2;
        margin-right: 0;
    }

    .heroCarousel__content {
        width: 100%;
        padding: 0 5vw;
    }

    .shop__products__singleProduct {
        width: 100%;
    }

    .shippingAndPayment__form {
        flex-direction: column;
    }

    .shippingAndPayment__form__section {
        width: 100%;
        margin-bottom: 0;
    }

    .d-900 {
        display: block;
    }

    .d-900-flex {
        display: flex !important;
    }

    .single {
        flex-direction: column;
    }

    .single__product {
        width: 100%;
        margin-top: 40px;
    }

    .single__product__main {
        flex-direction: column;
    }

    .single__product__main__imgWrapper, .single__product__main__content {
        width: 100%;
    }

    .single__product__main__imgWrapper {
        height: auto;
        margin-bottom: 30px;
    }
}

@media(max-width: 768px) {
    .contact {
        flex-direction: column;
        margin-bottom: 25px;
    }

    .contact__content, .contact__map {
        width: 100%;
    }

    .contact__map {
        margin-bottom: 30px;
    }

    .aboutUs__img--1 {
        position: relative;
        left: unset;
        top: unset;
        display: block;
        transform: none;
    }

    .aboutUs__content {
        margin: auto;
        max-width: 100%;
    }

    .footer__list__item__link {
        font-size: 13px;
    }

    .pageContent__witraze {
        flex-direction: column;
    }

    .pageContent__witraze__section {
        width: 100%;
        max-width: 500px;
        margin-bottom: 30px;
    }

    .pageContent__witrazeGrid__item {
        width: 100%;
    }

    .pageContent__artGallery__bigImage {
        display: block;
        margin: 30px auto;
        transform: none !important;
        width: var(--siteWidth);
    }

    .pageContent__artGallery__desc {
        position: relative;
        left: 0;
        width: var(--siteWidth);
        margin: auto;
    }

    .pageContent__artGallery__images {
        width: 100%;
        margin-top: 30px;
    }

    .aboutUs__section {
        flex-direction: column;
        width: var(--siteWidth);
        margin: 20px auto;
    }

    .aboutUs__section > * {
        width: 100%;
        margin-bottom: 40px;
    }

    .aboutUs__section .aboutUs__section__content {
        margin-left: 0;
        margin-right: 0;
    }

    .aboutUs__section__header,
    .aboutUs__section__content p {
        text-align: left !important;
    }

    .aboutUs__section__content--2 {
        order: 2;
    }

    .aboutUs__section__img--2 {
        order: 1;
    }

    .pageContent__article p {
        font-size: 14px;
    }

    .shop__main {
        flex-direction: column;
    }

    .shop__categories, .shop__products {
        flex-basis: 100%;
        width: 100%;
    }

    .shop__products {
        margin-top: 40px;
    }

    .shop {
        margin-bottom: 40px;
    }

    .shop__products__header {
        font-size: 24px;
    }

    .addToCartModal {
        width: 100%;
        height: 100%;
        border: none;
    }

    .addToCartModal__buttons {
        flex-wrap: wrap;
    }

    .button--addToCartModal {
        width: 150px;
        font-size: 12px;
        margin: 8px;
    }

    .addToCartModal__cartIcon {
        width: 120px;
    }

    .ty__header, .ty__subheader {
        font-size: 21px;
    }

    .button--ty {
        width: 100%;
        max-width: 400px;
    }

    .shop__categories__list__item {
        font-size: 14px;
        padding: 15px 0;
    }
}

@media(max-width: 576px) {
    .footer__section {
        width: 100%;
    }

    .footerHeader {
        font-size: 18px;
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 12px;
    }

    .footer__list__item {
        margin: 5px 0;
    }

    .footer__list--flex {
        flex-direction: column;
        align-items: center;
    }

    .topBar__logoLink__img {
        width: 100%;
    }

    .section--portfolio {
        margin-bottom: 60px;
    }

    .portfolioSection {
        flex-direction: column;
    }

    .portfolioItem {
        width: 100%;
        margin-bottom: 25px;
    }

    .button--process, .button--aboutUs, .button--portfolio {
        width: 100%;
        font-size: 15px;
    }

    .button--portfolio {
        margin-top: 10px;
    }

    .creationProcess {
        margin-top: 15px;
    }

    .creationProcess__desc,
    .technology__desc
    {
        font-size: 14px;
    }

    .aboutUs__img--1 {
        width: 100%;
    }

    .button--landing {
        max-width: 70%;
        font-size: 16px;
    }

    .heroCarousel__header {
        font-size: 27px;
    }

    .heroCarousel__subheader {
        font-size: 14px;
    }

    .topBar__facebookLink {
        font-size: 10px;
    }

    .pageContent__witrazeGrid__item {
        height: 300px;
    }

    .pageHeader {
        font-size: 24px;
    }

    .button--aboutUs {
        font-size: 13px !important;
        text-align: center;
    }

    .button--aboutUs:first-of-type {
        margin-right: 15px;
    }

    .button--aboutUs:last-of-type {
        margin-right: 0;
    }

    .pageContent--technologies {
        margin-bottom: 0;
    }

    .cart__sum {
        font-size: 18px;
    }

    /* TMP */
    .heroCarousel__image__img {
        left: -760px;
    }

    .heroCarousel__image__img:nth-of-type(2) {
        left: -300px;
    }

    .heroCarousel__image__img:last-of-type {
        left: -350px;
    }
}
